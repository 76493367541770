import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, from, lastValueFrom } from 'rxjs';
import { AuthenticationService } from '../global/service/authentication.service';

@Injectable()
export class UserInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  /**
   * Adds authorization token from auth service to all HTTP requests.
   * todo: replace with real implementation
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    const expire = Math.trunc(Date.now() / 1000) >= parseInt(localStorage.getItem('tokenExp') ?? '0') - 10 * 60; // refresh 10 minutes before experation
    let token = localStorage.getItem('token');
    if (expire && token) {
      try {
        const newToken = await this.authService.refreshToken();
        token = newToken.getJwtToken();
        localStorage.setItem('token', token);
        localStorage.setItem('tokenExp', newToken.payload['exp']);
      } catch (err) {
        this.authService.clearToken();
        this.authService.login(localStorage.getItem('username') ?? 'demo@vizerp.vn');
      }
    }
    const updatedRequest = request.clone({
      url: request.url.includes('http') ? request.url : environment.apiUrl + request.url,
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
    if (request.url.includes('/api/v1')) return await lastValueFrom(next.handle(updatedRequest));
    return await lastValueFrom(next.handle(request));
  }
}
